<template>
  <center-screen background-color="secondary">
    <v-form @submit.prevent>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="model.password"
            :rules="rules.password"
            :label="$t('user.newPassword')"
            name="password"
            append-icon="mdi-lock"
            type="password"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="model.confirmPassword"
            :rules="rules.confirmPassword"
            :label="$t('user.confirmPassword')"
            name="confirm"
            append-icon="mdi-lock-check"
            type="password"
          />
        </v-col>
      </v-row>
    </v-form>

    <template v-slot:actions>
      <v-btn text color="primary" tile depressed @click="navigateToLogin">
        {{ $t("login.login") }}
      </v-btn>
      <v-spacer />
      <v-btn color="primary" tile depressed @click="tryResetPassword">
        {{ $t("general.reset") }}
      </v-btn>
    </template>
  </center-screen>
</template>

<script>
import { mapActions } from "vuex";
import CenterScreen from "@/common/layouts/CenterScreen";

export default {
  name: "ResetPassword",
  components: { CenterScreen },
  data() {
    return {
      redirect: "/",
      model: { password: "", confirmPassword: "", resetCode: "" },
      rules: {
        password: [
          (v) => !!v || "Password is required",
          (v) =>
            (!!v && v.length >= 8) || "Password must be at least 8 characters",
        ],
        confirmPassword: [
          (v) => !!v || "Password is required",
          (v) =>
            v === this.model.password || "Password and confirmation must match",
        ],
      },
    };
  },
  methods: {
    ...mapActions("auth", ["resetPassword"]),

    tryResetPassword() {
      this.model.resetCode = this.$route.query.code;
      this.resetPassword(this.model).then(() =>
        this.$$router.replace({ name: "default" })
      );
    },
    navigateToLogin() {
      this.$$router.push({
        name: "login",
      });
    },
  },
};
</script>
